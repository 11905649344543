import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

import CustomField from './customField'
import Images from './images'
import Service from './service'
import Role from './role'

export default class Employee extends Model {
  static modelName () {
    return 'employee'
  }

  objectID () {
    return this.idEmployee
  }

  relations () {
    return {
      allowCustomerAccess: { type: EmployeeCustomerAccess },
      allowCustomerDetails: { type: EmployeeCustomerDetail },
      customFields: { type: CustomField },
      services: { type: EmployeeService },
      type: { type: EmployeeEmployeeType },
      images: { type: Images },
      role: { type: Role }
    }
  }

  static employees (page = 1, modifiedSince = null) {
    let url = this.modelBaseURL() + '?filter=employee&page=' + page

    if (modifiedSince) {
      url += '&modifiedSince=' + parseInt(modifiedSince.getTime() / 1000)
    }

    return this.requestList(Request.get(url), this)
  }

  static resources (page = 1, modifiedSince = null) {
    let url = this.modelBaseURL() + '?filter=resource&page=' + page

    if (modifiedSince) {
      url += '&modifiedSince=' + parseInt(modifiedSince.getTime() / 1000)
    }

    return this.requestList(Request.get(url), this)
  }

  static resourceTypes () {
    const url = this.modelBaseURL() + '/resourceTypes'
    return this.requestList(Request.get(url))
  }

  static commissions () {
    const url = this.modelBaseURL() + '/commissions'
    return this.requestList(Request.get(url))
  }

  addPhoto (image, options) {
    const url =
      this.constructor.modelBaseURL() + '/addPhoto?id=' + this.objectID()

    const formData = new FormData()
    formData.append('image', image)

    const request = Request.post(url, formData)
    request.options = options

    // return this.constructor.requestItem(request, Photo)

    return this.constructor
      .requestItem(request, this.constructor)
      .then(this.updateSelf(res => this.updateSelf(res)))
  }

  deletePhoto () {
    const url =
      this.constructor.modelBaseURL() + '/deletePhoto?id=' + this.objectID()
    return this.constructor
      .requestItem(Request.get(url), this.constructor)
      .then(this.updateSelf(res => this.updateSelf(res)))
  }

  delete ({ retainOldAppointments = true }) {
    const url =
      this.constructor.modelBaseURL() +
      '/' +
      this.objectID() +
      '?retain=' +
      (retainOldAppointments ? 1 : 0)
    return this.constructor.requestSuccess(Request.delete(url))
  }

  resetPassword ({ password, confirm, sendEmail, revokeSessions }) {
    const url =
      this.constructor.modelBaseURL() +
      '/resetPassword?id=' +
      this.objectID() +
      '&password=' +
      password +
      '&confirm=' +
      confirm +
      '&sendEmail=' +
      (sendEmail ? 1 : 0) +
      '&revokeSessions=' +
      (revokeSessions ? 1 : 0)
    return this.constructor.requestSuccess(Request.get(url))
  }

  resetPin ({ pin, confirm }) {
    const url =
      this.constructor.modelBaseURL() + '/resetPin?id=' + this.objectID()
    const data = { pin, confirm }
    return this.constructor.requestSuccess(Request.post(url, data))
  }

  assignRole (role) {
    const url =
      this.constructor.modelBaseURL() + '/' + this.objectID() + '/assignRole'

    const data = {
      roleType: role?.type?.value || 'custom',
      idCustomRole: role?.idCustomRole
    }

    return this.constructor.requestItem(
      Request.jsonPost(url, data),
      this.constructor
    )
  }

  static changeOwner ({ token, code, newOwner } = {}) {
    const url = this.modelBaseURL() + '/changeOwner'
    const data = {
      token,
      code,
      newOwner
    }
    return this.requestData(Request.jsonPost(url, JSON.stringify(data)))
  }

  changeEmail ({ token, code, email } = {}) {
    const url =
      this.constructor.modelBaseURL() + '/' + this.objectID() + '/changeEmail'
    const data = {
      token,
      code,
      email
    }
    return this.constructor.requestData(
      Request.jsonPost(url, JSON.stringify(data))
    )
  }

  getEmployeeServiceForService (service) {
    if (this.employeeServiceData) {
      for (const employeeService of this.employeeServiceData) {
        if (employeeService.service.idService === service.idService) {
          return employeeService
        }
      }
    }

    if (service.employees) {
      for (const employeeService of service.employees) {
        if (employeeService.employee.idEmployee === this.idEmployee) {
          return employeeService
        }
      }
    }

    return null
  }

  updatePrices ({ updateFutureReservations = false } = {}) {
    const updateFutureReservationsParam = updateFutureReservations ? 1 : 0
    const url =
      this.constructor.modelBaseURL() +
      '/' +
      this.objectID() +
      '/updatePrices?updateFutureReservations=' +
      updateFutureReservationsParam

    return this.constructor.requestSuccess(Request.post(url))
  }

  static compare (lhs, rhs) {
    if (lhs && !rhs) {
      return -1
    } else if (rhs && !lhs) {
      return 1
    } else if (!rhs && !lhs) {
      return null
    }

    if (lhs.type?.value === rhs.type?.value) {
      return lhs.listOrder - rhs.listOrder
    }

    return lhs.type?.value - rhs.type?.value
  }

  get label () {
    return this.displayName
  }

  get image () {
    if (this.hasImage) {
      return this.images.square
    }

    return null
  }

  get initials () {
    return (
      (this.firstName ? this.firstName.charAt(0) : '') +
      (this.lastName ? this.lastName.charAt(0) : '')
    ).toUpperCase()
  }

  get overridesLabel () {
    let label = ''

    label +=
      this.overrides.hasPriceOverrides && !this.overrides.hasZeroPricesOverrides
        ? 'Some services have price overrides. '
        : ''
    label += this.overrides.hasZeroPricesOverrides
      ? 'Some services have 0 price overrides. '
      : ''
    label += this.overrides.hasTimingOverrides
      ? 'Some services have timing overrides. '
      : ''

    return label
  }

  get hasOverrides () {
    return (
      this.overrides.hasPriceOverrides ||
      this.overrides.hasTimingOverrides ||
      this.overrides.hasZeroPricesOverrides
    )
  }

  get isEmployeeType () {
    return this.type === EmployeeEmployeeType.employee
  }

  get isResourceType () {
    return this.type === EmployeeEmployeeType.resource
  }

  get isAdministratorType () {
    return this.type === EmployeeEmployeeType.admin
  }
}

export const EmployeeEmployeeType = new Enum({
  employee: { value: 0, description: 'Employee' },
  resource: { value: 1, description: 'Resource' },
  admin: { value: 2, description: 'Admin' }
})

export const EmployeeCustomerAccess = new Enum({
  all: { value: 0, description: 'All' },
  reservation: { value: 1, description: 'With Previous Appointments' }
})

export const EmployeeCustomerDetail = new Enum({
  none: { value: 0, description: 'None' },
  view: { value: 1, description: 'View' },
  edit: { value: 2, description: 'Edit' }
})

export class EmployeeService extends APIObject {
  relations () {
    return {
      service: { type: Service }
    }
  }

  get availableDaysLabel () {
    const allowedDaysOfWeek = this.allowedDaysOfWeek || this.allowedDays

    const isAllowedAllDays = Object.values(allowedDaysOfWeek).every(
      item => !!item
    )

    if (isAllowedAllDays) {
      return 'All'
    }

    const isAllowedNoDays = Object.values(allowedDaysOfWeek).every(
      item => !item
    )

    if (isAllowedNoDays) {
      return 'None'
    }

    return 'Limited'
  }

  get isAllowedDaysAll () {
    const allowedDaysOfWeek = this.allowedDaysOfWeek || this.allowedDays
    return Object.values(allowedDaysOfWeek).every(item => !!item)
  }

  get isAllowedDaysNone () {
    const allowedDaysOfWeek = this.allowedDaysOfWeek || this.allowedDays
    return Object.values(allowedDaysOfWeek).every(item => !item)
  }

  get isAllowedDaysLimited () {
    return !this.isAllowedDaysAll && !this.isAllowedDaysNone
  }
}

export class ResourceType extends APIObject {
  relations () {
    return {
      service: { type: Service }
    }
  }
}
