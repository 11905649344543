<template>
  <container
    v-bind="container"
    class="relative w-full"
    :base-class="[
      'w-full relative flex items-center h-9 text-gray-800 text-sm relative dark:text-gray-100',
      disabled ? 'opacity-75 pointer-events-none' : ''
    ]"
  >
    <o-dropdown
      ref="dropdown"
      class="w-full"
      :arrow="false"
      placement="bottom-start"
      container-class="-mx-2"
      detect-overflow
      :disabled="disabled"
      @show="$emit('show')"
    >
      <div class="w-full h-9 relative flex items-center justify-between text-sm text-gray-800 cursor-pointer pr-4 px-2 dark:text-gray-100">
        <slot v-if="!isEmpty" name="value" :value="value">
          <span class="truncate flex-shrink max-w-[120px]">
            {{ valueLabel }}
          </span>
        </slot>

        <p v-if="isEmpty" class="text-sm text-gray-500 dark:text-gray-400">
          {{ placeholder }}
        </p>

        <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-600">
          <o-icon icon="accordion" :size="12" />
        </div>
      </div>

      <template #content="{ overflow }">
        <div
          class="text-sm flex flex-col w-120"
          :style="{
            height: overflow && overflow.bottom > 0 ? `${400 - overflow.bottom}px` : '400px'
          }"
        >
          <div v-if="search" class="w-full flex items-center border-b">
            <o-icon icon="search" class="text-gray-500 dark:text-gray-400 ml-4" :size="14" />
            <input v-model="query" type="text" placeholder="Search" class="py-2 px-3 w-full dark:bg-gray-900">
          </div>

          <slot name="content">
            <o-scroll v-if="filtered">
              <ul
                v-for="(category, indexCategory) in filtered"
                :key="indexCategory"
              >
                <li v-if="category.label" class="text-gray-500 dark:text-gray-400 text-xs uppercase tracking-wider mt-3 mb-1 px-4">
                  {{ category.label }}
                </li>
                <li
                  v-for="(option, indexOption) in category.options"
                  :key="indexOption"
                  class="text-gray-800 cursor-pointer select-none relative py-2 px-3 transition-colors dark:text-gray-100"
                  :class="[
                    (isMulti ? _value.includes(option.value) : option.value === value) ? 'bg-blue-50 text-blue-700 hover:bg-blue-100 dark:bg-blue-800/20 dark:text-blue-300 dark:hover:bg-blue-900/50' : 'hover:bg-gray-100 dark:hover:bg-gray-800',
                    option.class
                  ]"
                  role="option"
                  @click="handleOptionClick(option.value)"
                >
                  <slot name="option" :option="option">
                    <span class="w-full flex items-center justify-between">
                      {{ option.label }}

                      <o-icon
                        v-if="isMulti ? _value.includes(option.value) : option.value === value"
                        icon="check"
                        :size="9"
                        class="text-blue-600 dark:text-blue-400"
                        fill
                      />
                    </span>
                  </slot>
                </li>
              </ul>
            </o-scroll>

            <p v-else class="text-center p-4 text-gray-600 dark:text-gray-300">
              No options found.
            </p>
          </slot>

          <div v-if="clear && !isEmpty" class="w-full border-t pt-1 px-1">
            <ul>
              <li
                class="text-gray-800 cursor-pointer select-none relative py-1 transition-colors hover:bg-gray-100 w-full block text-center rounded"
                role="option"
                @click="handleClear"
              >
                <span class="w-full flex items-center justify-center">
                  Clear selected

                  <o-icon
                    icon="x"
                    :size="12"
                    class="text-blue-600 ml-2"
                    fill
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </o-dropdown>

    <template #leading>
      <slot name="leading" />
    </template>

    <template #trailing>
      <slot name="trailing" />
    </template>
  </container>
</template>

<script>
import Fuse from 'fuse.js'

import Container from './Container'

import classes from '@/mixins/fields/classes.js'
import attributes from '@/mixins/fields/attributes.js'
import methods from '@/mixins/fields/methods.js'
import multi from '@/mixins/fields/multi.js'
import input from '@/mixins/fields/input.js'

export default {
  components: {
    Container
  },
  mixins: [attributes, classes, methods, multi, input],
  props: {
    search: {
      type: Boolean,
      default: false
    },
    categorised: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Object, String, Number],
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Select an option'
    },
    clear: {
      type: Boolean,
      default: false
    },
    searchKeys: {
      type: Array,
      default: () => [
        'text',
        'info',
        'value',
        'label'
      ]
    },
    defaultSizeClass: {
      type: [String, Object, Array],
      default: 'h-9'
    },
    largeSizeClass: {
      type: [String, Object, Array],
      default: 'text-lg h-12'
    },
    smallSizeClass: {
      type: [String, Object, Array],
      default: 'text-sm h-8'
    }
  },
  data () {
    return {
      query: ''
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    filtered () {
      let items = this.options

      if (this.query.length) {
        if (this.categorised) {
          const arrays = Object.values(items).map(({ options }) => options)
          items = [].concat(...arrays)
        }

        const fuse = new Fuse(items, {
          keys: this.searchKeys,
          distance: 200
        })
        const results = fuse.search(this.query)
        items = results.map(({ item }) => item)
      }

      if (!this.categorised || this.query.length) {
        return [{
          options: items
        }]
      }
      return items
    },
    valueLabel () {
      if (this.isMulti) {
        return this._value
          .map(item => item ? item.name || item.title || item.displayName : 'None')
          .join(', ')
      }

      return this._value.name || this._value.title || this.value.displayName
    },
    isMulti () {
      return this.value && Array.isArray(this.value)
    },
    isEmpty () {
      if (this.isMulti) {
        return this.value?.length === 0
      }

      return this.value === null || this.value === undefined
    }
  },
  methods: {
    handleClear () {
      this._value = this.isMulti ? [] : null
    },
    handleOptionClick (value) {
      if (this.isMulti) {
        if (!this._value.includes(value)) {
          this._value.push(value)
        } else {
          const i = this._value.indexOf(value)
          if (i >= 0) {
            this._value.splice(i, 1)
          }
        }
      } else {
        this._value = value
        this.query = ''

        this.$refs.dropdown.close()
      }
    }
  }
}
</script>
