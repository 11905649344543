export const state = () => ({
  search: false,
  menu: false,
  sidebar: false,
  mobile: false,
  theme: 'ovatu',
  panel: {
    mode: 'panel'
  },
  schedule: {
    showResources: 'all',
    sectionSize: 200
  },
  alerts: {
    filter: false
  },
  dashboard: {
    showPastReservations: false
  },
  order: {
    print: 'thermal'
  },
  width: null,
  maintenance: null,
  hideBanner: false
})

export const actions = {
  nuxtServerInit ({ state, commit, dispatch }, { req, app, route }) {

  },
  toggleMobile ({ commit, state }) {
    commit('setMobile', !state.mobile)
  },
  toggleMenu ({ commit, state }) {
    commit('setMenu', !state.menu)
  },
  toggleSidebar ({ commit, state }) {
    commit('setSidebar', !state.sidebar)
  },
  toggleSearch ({ commit, state }) {
    commit('setSearch', !state.search)
  },
  hideBanner ({ commit }) {
    commit('setHideBanner', true)
  }
}
export const mutations = {
  setMobile (state, mobile) {
    state.mobile = mobile
  },
  setSearch (state, search) {
    state.search = search
  },
  setWidth (state, width) {
    state.width = width
  },
  setSchedule (state, { key, value }) {
    state.schedule[key] = value
  },
  setAlerts (state, { key, value }) {
    state.alerts[key] = value
  },
  setDashboard (state, { key, value }) {
    state.dashboard[key] = value
  },
  setOrder (state, { key, value }) {
    state.order[key] = value
  },
  setMenu (state, menu) {
    state.menu = menu
  },
  setSidebar (state, sidebar) {
    state.sidebar = sidebar
  },
  setMaintenance (state, maintenance) {
    state.maintenance = maintenance
  },
  setTheme (state, theme) {
    state.theme = theme
  },
  setHideBanner (state, hideBanner) {
    state.hideBanner = hideBanner
  }
}

export const getters = {
  mobile (state) {
    return state.mobile
  },
  search (state) {
    return state.search
  },
  width (state) {
    return state.width
  },
  schedule (state) {
    return state.schedule
  },
  alerts (state) {
    return state.alerts
  },
  dashboard (state) {
    return state.dashboard
  },
  order (state) {
    return state.order
  },
  isMobile (state) {
    if (state.width < 980) {
      return true
    }

    return false
  },
  menu (state) {
    return state.menu
  },
  sidebar (state) {
    return state.sidebar
  },
  maintenance (state) {
    return state.maintenance
  },
  theme (state) {
    return state.theme
  },
  hideBanner (state) {
    return state.hideBanner
  }
}
